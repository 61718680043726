<template>
  <div class="wrap">
    <div class="zh-img">
      <img src="@/assets/cat.jpg" alt="">
    </div>
    <div class="user-name">{{userNickName}}</div>
    <div class="txt">您好，请输入OA账号进行绑定</div>
    <form class="form">
      <van-field v-model="account" label="账号" input-align="right" placeholder="请输入OA账号"/>
      <van-field v-model="password" type="password" label="密码" input-align="right" placeholder="请输入OA密码"/>
    </form>
    <div class="footer" @click="bindingAccount">绑定</div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { accountBind } from '@/api/index'
export default {
  data() {
    return {
      account: '',
      password: '',
      wxAccount: ''
    };
  },
  computed:{
    ...mapGetters(['userNickName','tenantId','userId'])
  },
  created() {
    
  },
  methods: {
    bindingAccount(){
      const data = {
        userId : this.userId,
        password : this.password,
        account: this.account
      }
      accountBind(data).then(res=>{
        if(res.code==200){
          this.$toast({message:'OA账号绑定成功！',type:'success'});
          this.$router.push('index');
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap{
  .zh-img{
    padding: 0.46rem 1.44rem;
    padding-bottom: 0.14rem;
    img{
      width: 0.88rem;
      height: 0.88rem;
      border-radius: 50%;
    }
  }
  .user-name{
    text-align: center;
    font-size: 0.15rem;
    color: #333;
  }
  .txt{
    text-align: center;
    font-size: 0.13rem;
    color: #888;
    padding-top: 0.08rem;
    padding-bottom: 0.19rem;
  }
  .footer{
    margin: 0 0.12rem;
    margin-top: 0.43rem;
    background: #3A75C5;
    padding: 0.12rem 0;
    color: #fff;
    text-align: center;
    border-radius: 0.04rem;
  }
}
</style>